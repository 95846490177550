<template>
	<div>
		<el-dialog
			:title="title"
			:visible.sync="open"
			width="700px"
			@close="commitOnClose"
		>
			<el-form
				ref="saveOrEditForm"
				:model="saveData"
				label-width="150px"
				style="width:600px"
				:rules="rules"
			>
				<!-- <el-form-item label="提现时间" prop="extractionDate" v-if="isBatch">
                    <el-date-picker placeholder="选择日期" v-model="saveData.extractionDate" value-format="yyyy-MM-dd" type="date" style="width: 100%;"></el-date-picker>
                </el-form-item> -->
				<el-form-item label="审核意见" prop="auditStatus">
					<el-select
						v-model="saveData.auditStatus"
						placeholder="请选择审核意见"
						style="width: 240px"
					>
						<el-option label="通过" value="SUCCESS"></el-option>
						<el-option label="不通过" value="FAILED"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="税筹通道" prop="paymentNo">
					<el-select
						v-model="saveData.paymentNo"
						placeholder="请选择税筹通道"
						style="width: 240px"
					>
						<el-option
							v-for="item in acqChannelList"
							:key="item.id"
							:label="item.paymentName"
							:value="item.paymentNo"
						>
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="备注">
					<el-input
						v-model="saveData.auditRemark"
						type="textarea"
						placeholder="请输入备注"
					/>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button type="primary" @click="submit">确定</el-button>
				<el-button type="primary" @click="cancel">取消</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
import { WithdrawApi } from "@/api";
export default {
	name: "CustomForm2",
	props: {
		dicts: {
			type: Object,
			default: function() {
				return {};
			}
		},
		isBatch: {
			type: Boolean,
			default: false
		},
		selectRow: {
			type: Object,
			default: function() {
				return {};
			}
		},
		onFresh: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			title: "",
			saveData: {},
			open: false,
			rules: {
				extractionDate: [
					{
						required: true,
						message: "请选择提现时间",
						trigger: "change"
					}
				],
				auditStatus: [
					{
						required: true,
						message: "请选择审核意见",
						trigger: "change"
					}
				],
				paymentNo: [
					{
						required: true,
						message: "请选择税筹通道",
						trigger: "change"
					}
				]
			},
			options: [],
			userNo: "",
			acqChannelList: [],
			receivedIDList: []
		};
	},
	watch: {
		onFresh: function(value) {
			this.open = value;
		}
	},
	created() {},
	mounted() {
		WithdrawApi.extractionOrder.listAcqPaymentChannel().then(res => {
			if (res.success) {
				this.acqChannelList = res.data;
			}
		});
	},
	methods: {
		// 提交事件
		submit() {
			// 校验表单数据
			this.$refs.saveOrEditForm.validate(valid => {
				//判断校验通过则valid为true
				if (valid) {
					this.submitForm();
				} else {
					return false;
				}
			});
		},
		// 提交表单数据
		async submitForm() {
			// 赋值单个审核数据给saveData
			this.saveData.orderNo = this.selectRow.orderNo;
			console.log("this.saveData----->submitForm", this.saveData);
			// 单个审核提现订单
			const result = await WithdrawApi.extractionOrder.audit(
				this.saveData
			);
			console.log("audit----->155", result);
			// 判断为true则显示成功
			if (result.success === true) {
				this.Message.success("审核成功");
			} else {
				this.Message.error(result.message);
			}
			// 清空选中ID
			this.$store.commit("app/deleteReceivedID");
			this.saveData = {};
			this.commitOnClose();
		},
		// 关闭
		cancel() {
			this.saveData = {};
			this.commitOnClose();
		},
		commitOnClose() {
			// 清空校验规则
			this.$refs.saveOrEditForm.resetFields();
			this.$emit("on-close");
		}
	}
};
</script>

<style scoped></style>
